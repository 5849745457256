import React from 'react';
import logo from '../assets/images/logo.jpg';

export default () => (
  <div className="container">
    <div className="hero">
      <img src={logo} alt="Emmanuelle Lucy" />
    </div>
  </div>
);
